import styled from "styled-components";

export const LoginContainer = styled.div`
    width: 280px;
    height: 400px;
    display: block;
    margin: calc((100vh - 400px) / 2) auto;

    border: 1px solid black;
    border-radius: 16px;

`

export const ErrorField = styled.p`
    color: #FF0000;
`