import { useState, useEffect } from 'react';
import { LoginContainer, ErrorField } from './LoginStyle';
import { TextField, Button } from "@mui/material";
import { UsersLogin } from '../../paths';

const Login = () => {


    //Control input usuario
    const [usuario, setUsuario] = useState(''); 
    const typeInUsuario = (event) => {
        setUsuario(event.target.value);
        //console.log(event.target.value)
    }


    //Control input password
    const [password, setPassword]   = useState(''); 
    const typeInPassword = (event) => {
        setPassword(event.target.value);
        //console.log(event.target.value);
    }

    
    //Control mensaje datos incorrectos
    const [error, setError] = useState(false);
    useEffect(() => {
      }, [error]);



    //Control submit
    const submit = () => {
        
        const url   = UsersLogin;
        const data  = {
            'name_user': usuario,
            'pass_user': password
        }
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            console.log('Respuesta del servidor:', data);
            if(data.success && data.data.length > 0){
                setError(false);
            
            }else{
                setError(true);
            }
        })
        .catch(error => {
          console.error('Error:', error);
          // Manejar el error de alguna manera
        });
    }



    return <LoginContainer>

        <TextField id="outlined-basic" 
            label="Usuario" 
            variant="outlined"
            value = {usuario}
            onChange={typeInUsuario}
        />

        <TextField id="outlined-basic" 
            label="Contraseña" 
            variant="outlined" 
            type = "password"

            value={password}
            onChange={typeInPassword}
        />
        
        {(error) ? <ErrorField> Datos incorrectos </ErrorField> : null}
        
        <Button 
            variant="contained"
            onClick={submit}
        >
            Ingresar
        </Button>

    </LoginContainer>
}
export default Login;